import axios from "axios";
import { CONFIG } from "../../config";
import { i18n } from "../../i18n";
import {
  SHOW_LOADER,
  HIDE_LOADER,
  ACTIVE_TAB,
  APP_ERROR,
  SET_LOGIN_ATTEMPTS,
  INIT_LOGIN_ATTEMPTS,
  SET_LANGUAGELIST_FOR_CLIENT,
  SET_SELECTED_LANGUAGE,
  CLEAR_LOGIN_ATTEMPTS,
  SET_CLIENT_CONFIG,
  GET_NOTIFICATIONS,
  UPDATE_ACCESSIBILITY_CLASS_LIST,
  UPDATE_ACCESSIBILITY_IS_ACTIVE,
} from "../actions/common";

const state = {
  isLoading: false,
  clientCfg: {},
  errorMessage: "",
  loginAttempts: {},
  languages: {},
  activeTab: "",
  selectedLanguage: "en",
  notifications: [],
  accessibilityClassList: "",
  accessibilityIsActive: false,
};

const getters = {
  isLoading: (state) => state.isLoading,
  getErrorMessage: (state) => state.errorMessage,
  loginAttempts: (state) => state.loginAttempts,
  languages: (state) => state.languages,
  getActiveTab: (state) => state.activeTab,
  selectedLanguage: (state) => state.selectedLanguage,
  getClientConfig: (state) => state.clientCfg,
  getNotifications: (state) => state.notifications,
  accessibilityClassList: (state) => state.accessibilityClassList,
  accessibilityIsActive: (state) => state.accessibilityClassList,
};

const actions = {
  [SHOW_LOADER]: ({ commit }) => {
    commit(SHOW_LOADER);
  },
  [HIDE_LOADER]: ({ commit }) => {
    commit(HIDE_LOADER);
  },
  [ACTIVE_TAB]: ({ commit }, tab) => {
    commit(ACTIVE_TAB, tab);
  },
  [APP_ERROR]: ({ commit }, err) => {
    commit(APP_ERROR, err);
  },
  [SET_LOGIN_ATTEMPTS]: ({ commit }, loginAttempts) => {
    commit(SET_LOGIN_ATTEMPTS, loginAttempts);
  },
  [SET_SELECTED_LANGUAGE]: ({ commit }, selectedLang) => {
    commit(SET_SELECTED_LANGUAGE, selectedLang);
  },
  [SET_LANGUAGELIST_FOR_CLIENT]: ({ commit }, client) => {
    if (client == null) return;
    axios({
      url: CONFIG.BACKEND_URL + "api/I18n/languages",
      data: Object.assign({}, { clientName: client }),
      method: "POST",
    })
      .then((response) => {
        commit(SET_LANGUAGELIST_FOR_CLIENT, response.data);
      })
      .catch((err) => {
        console.log("SET_LANGUAGELIST_FOR_CLIENT errored", err);
      });
  },
  [GET_NOTIFICATIONS]: ({ commit }, { clientName, lang }) => {
    var notifications = JSON.parse(sessionStorage.getItem("notifications" + lang));
    if (!notifications || notifications.length == 0) {
      axios({
        // url: CONFIG.BACKEND_URL + `api/I18n/notifications`,
        // data: Object.assign({}, { clientName: client }),
        url: CONFIG.BACKEND_URL + `api/I18n/notifications`,
        data: {
          client: clientName,
          locale: lang,
        },
        method: "POST",
      })
        .then((response) => {
          commit(GET_NOTIFICATIONS, response.data);
          sessionStorage.setItem("notifications" + lang, JSON.stringify(response.data));
        })
        .catch((err) => {
          console.log("GET_NOTIFICATIONS errored", err);
        });
    } else commit(GET_NOTIFICATIONS, notifications);
  },
  [INIT_LOGIN_ATTEMPTS]: ({ commit }) => {
    commit(INIT_LOGIN_ATTEMPTS);
  },
  [CLEAR_LOGIN_ATTEMPTS]: ({ commit }) => {
    commit(CLEAR_LOGIN_ATTEMPTS);
  },
  [SET_CLIENT_CONFIG]: ({ commit }, client) => {
    commit(SET_CLIENT_CONFIG, client);
  },
  [UPDATE_ACCESSIBILITY_CLASS_LIST]: ({ commit }) => {
    const classList = window.cambiumAccessibilityWidgetGetClassListFromState();
    commit(UPDATE_ACCESSIBILITY_CLASS_LIST, classList.join(","));
  },
  [UPDATE_ACCESSIBILITY_IS_ACTIVE]: ({ commit }) => {
    const isActive = window.cambiumAccessibilityWidgetAnyToolsActive();
    commit(UPDATE_ACCESSIBILITY_IS_ACTIVE, isActive);
  },
};

const mutations = {
  [SHOW_LOADER]: (state) => {
    state.isLoading = true;
  },
  [HIDE_LOADER]: (state) => {
    state.isLoading = false;
  },
  [ACTIVE_TAB]: (state, tab) => {
    state.activeTab = tab;
  },
  [APP_ERROR]: (state, message) => {
    state.isLoading = false;
    state.errorMessage = message;
    let useGenericErrorMessage = true;
    if (message.response) {
      if (message.response.data.message !== undefined) {
        state.errorMessage = message.response.data.message;
        useGenericErrorMessage = false;
      }
    }
    if (useGenericErrorMessage) {
      state.errorMessage = i18n.global.t("ui.error.service_unavailable");
      if (state.errorMessage === "ui.error.service_unavailable") {
        state.errorMessage = "Something went wrong. Please try again in a few minutes.";
      }
    }
  },
  [INIT_LOGIN_ATTEMPTS]: (state) => {
    var loginObj = Object.assign({}, { timestamp: Date.now(), count: 0 });
    if (!localStorage.getItem("loginAttempts")) {
      localStorage.setItem("loginAttempts", JSON.stringify(loginObj));
      state.loginAttempts = loginObj;
    } else {
      state.loginAttempts = JSON.parse(localStorage.getItem("loginAttempts"));
    }
  },
  [SET_LOGIN_ATTEMPTS]: (state) => {
    var loginObj = Object.assign({}, { timestamp: Date.now(), count: 1 });
    if (localStorage.getItem("loginAttempts")) {
      loginObj = JSON.parse(localStorage.getItem("loginAttempts"));
      loginObj.timestamp = Date.now();
      loginObj.count = loginObj.count + 1;
    }
    localStorage.setItem("loginAttempts", JSON.stringify(loginObj));
    state.loginAttempts = loginObj;
  },
  [SET_SELECTED_LANGUAGE]: (state, selectedLang) => {
    localStorage.setItem("locale", selectedLang);
    if (selectedLang) {
      state.selectedLanguage = selectedLang;
    }
  },
  [GET_NOTIFICATIONS]: (state, notifications) => {
    state.notifications = notifications;
  },
  [SET_LANGUAGELIST_FOR_CLIENT]: (state, languagesList) => {
    state.languages = languagesList;
  },
  [CLEAR_LOGIN_ATTEMPTS]: (state) => {
    var loginObj = JSON.stringify(Object.assign({}, { timestamp: Date.now(), count: 0 }));
    localStorage.setItem("loginAttempts", loginObj);
    state.loginAttempts = Object.assign({}, { timestamp: Date.now(), count: 0 });
  },
  [SET_CLIENT_CONFIG]: (state, clientcfg) => {
    state.clientCfg = clientcfg;
  },
  [UPDATE_ACCESSIBILITY_CLASS_LIST]: (state, classList) => {
    state.accessibilityClassList = classList;
  },
  [UPDATE_ACCESSIBILITY_IS_ACTIVE]: (state, isActive) => {
    state.accessibilityIsActive = isActive;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
