export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const APP_ERROR = "APP_ERROR";
export const ACTIVE_TAB = "ACTIVE_TAB";
export const SET_LOGIN_ATTEMPTS = "SET_LOGIN_ATTEMPTS";
export const INIT_LOGIN_ATTEMPTS = "INIT_LOGIN_ATTEMPTS";
export const SET_LANGUAGELIST_FOR_CLIENT = "SET_LANGUAGELIST_FOR_CLIENT";
export const SET_SELECTED_LANGUAGE = "SET_SELECTED_LANGUAGE";
export const CLEAR_LOGIN_ATTEMPTS = "CLEAR_LOGIN_ATTEMPTS";
export const SET_CLIENT_CONFIG = "SET_CLIENT_CONFIG";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const UPDATE_ACCESSIBILITY_CLASS_LIST = "UPDATE_ACCESSIBILITY_CLASS_LIST";
export const UPDATE_ACCESSIBILITY_IS_ACTIVE = "UPDATE_ACCESSIBILITY_IS_ACTIVE";
