<script>
import IframeModal from "./IframeModal.vue";
import { mapGetters } from "vuex";
import { CONFIG } from "./../config";
import { localizationMixin } from "../mixins/localizationMixin";

export default {
  components: { IframeModal },
  name: "LoginPopupLinks",
  mixins: [localizationMixin],
  data: () => ({
    iframeDialogModal: false,
    iframeTitle: "",
    iframeSource: "",
    resourceclass: "full_modal",
    activeElement: "",
  }),
  computed: {
    ...mapGetters(["getClientConfig", "selectedLanguage", "accessibilityClassList"]),
    accessCodeHelpUrl() {
      if (this.getClientConfig.layout === "full")
        return (
          CONFIG.PORTAL_BASE_URL +
          this.getClientConfig.clientname +
          "/" +
          this.selectedLanguage +
          "/login_help/getting_access_code.html"
        );
      else
        return (
          CONFIG.PORTAL_BASE_URL +
          this.getClientConfig.clientname +
          "/" +
          this.selectedLanguage +
          "/How+do+I+get+my+access+code.pdf"
        );
    },
    loginHelpUrl() {
      if (this.getClientConfig.layout === "full")
        return (
          CONFIG.PORTAL_BASE_URL +
          this.getClientConfig.clientname +
          "/" +
          this.selectedLanguage +
          "/login_help/having_trouble_logging_in.html"
        );
      else
        return (
          CONFIG.PORTAL_BASE_URL +
          this.getClientConfig.clientname +
          "/" +
          this.selectedLanguage +
          "/Having+Trouble+Logging+In.pdf"
        );
    },
    releaseDatesUrl() {
      if (this.getClientConfig.layout === "full")
        return (
          CONFIG.PORTAL_BASE_URL +
          this.getClientConfig.clientname +
          "/" +
          this.selectedLanguage +
          "/login_help/test_score_release_dates.html"
        );
      else
        return (
          CONFIG.PORTAL_BASE_URL +
          this.getClientConfig.clientname +
          "/" +
          this.selectedLanguage +
          "/Having+Trouble+Logging+In.pdf"
        );
    },
    moreHelpUrl() {
      if (this.getClientConfig.layout === "full")
        return (
          CONFIG.PORTAL_BASE_URL +
          this.getClientConfig.clientname +
          "/" +
          this.selectedLanguage +
          "/login_help/need_more_help.html"
        );
      else
        return (
          CONFIG.PORTAL_BASE_URL +
          this.getClientConfig.clientname +
          "/" +
          this.selectedLanguage +
          "/Need+more+help.pdf"
        );
    },
    supportedBrowsersUrl() {
      return (
        CONFIG.PORTAL_BASE_URL +
        this.getClientConfig.clientname +
        "/" +
        this.selectedLanguage +
        "/supported_browsers/index.html"
      );
    },
    fAQsUrl() {
      return (
        CONFIG.PORTAL_BASE_URL +
        this.getClientConfig.clientname +
        "/" +
        this.selectedLanguage +
        "/login_help/faqs.html"
      );
    },
    accessCodeLinkUrl() {
      return new URL(
        this.$router.resolve({ name: "AccessCode", params: { lang: this.selectedLanguage } }).href,
        window.location.origin
      ).href;
    },
    iFrameQueryParams() {
      const queryParams = [];
      if (this.accessibilityClassList !== "") {
        queryParams.push("accessibilityClasses=" + this.accessibilityClassList);
      }
      if (queryParams.length > 0) {
        return `${queryParams.join("&")}`;
      }
      return "";
    },
  },
  methods: {
    openResource(url, iframeTitle = "", elmId = "") {
      this.iframeDialogModal = true;
      this.iframeTitle = iframeTitle;
      this.iframeSource = url;
      this.activeElement = elmId;
    },
    close: function () {
      this.iframeDialogModal = false;
      this.iframeSource = "";
      if (this.activeElement != "") document.getElementById(this.activeElement).focus();
    },
  },
};
</script>

<template>
  <div id="login_help">
    <div id="login_more_info">
      <!-- LoginLinks.moreInfo -->
      <h2>{{ translate("LoginLinks.Info") }}</h2>
      <ul v-if="getClientConfig.layout === 'full'">
        <li>
          <a
            id="lnkAccCodeH"
            href="#"
            :aria-label="translate('LoginLinks.accesscode')"
            @click="
              openResource(accessCodeHelpUrl, translate('LoginLinks.accesscode'), 'lnkAccCodeH')
            "
          >
            {{ translate("LoginLinks.accesscode") }}</a
          >
        </li>
        <li>
          <a
            id="lnkAccCodeLink"
            href="#"
            :aria-label="translate('LoginLinks.accesscodelink')"
            @click="
              openResource(
                accessCodeLinkUrl,
                translate('LoginLinks.accesscodelink'),
                'lnkAccCodeLink'
              )
            "
          >
            {{ translate("LoginLinks.accesscodelink") }}
          </a>
          <!-- 
          <a href="#" @click="() => $router.push('/AccessCode')">
            {{ translate("LoginLinks.accesscodelink") }}
            </a>
           -->
        </li>
        <li>
          <!-- <a @click="openResource(loginhelp)" tabindex="0"
            ><span class="sr-only">PDF Opens in new window</span>
            {{ translate("LoginLinks.loginhelp") }}</a
          > -->
          <a
            id="lnkRelease"
            href="#"
            :aria-label="translate('LoginLinks.releasedates')"
            @click="
              openResource(releaseDatesUrl, translate('LoginLinks.releasedates'), 'lnkRelease')
            "
          >
            {{ translate("LoginLinks.releasedates") }}</a
          >
        </li>
        <li>
          <a
            id="lnkFaqs"
            href="#"
            :aria-label="translate('LoginLinks.faqs')"
            @click="openResource(fAQsUrl, translate('LoginLinks.faqs'), 'lnkFaqs')"
          >
            {{ translate("LoginLinks.faqs") }}</a
          >
        </li>
      </ul>
      <ul v-else>
        <li>
          <a
            id="lnkAccCodeH2"
            href="#"
            :aria-label="translate('LoginLinks.accesscodehelp')"
            @click="
              openResource(
                accessCodeHelpUrl,
                translate('LoginLinks.accesscodehelp'),
                'lnkAccCodeH2'
              )
            "
          >
            {{ translate("LoginLinks.accesscodehelp") }}</a
          >
        </li>
        <li>
          <a
            id="lnkAccCodeH2"
            href="#"
            :aria-label="translate('LoginLinks.loginhelp')"
            @click="openResource(loginHelpUrl, translate('LoginLinks.loginhelp'))"
          >
            {{ translate("LoginLinks.loginhelp") }}</a
          >
        </li>
        <li>
          <a
            id="lnkMoreHelp"
            href="#"
            :aria-label="translate('LoginLinks.morehelp')"
            @click="openResource(moreHelpUrl, translate('LoginLinks.morehelp'), 'lnkMoreHelp')"
          >
            {{ translate("LoginLinks.morehelp") }}</a
          >
        </li>
      </ul>
    </div>
    <div id="login_more_support">
      <h2>{{ translate("LoginLinks.moresupport") }}</h2>
      <ul>
        <li v-if="getClientConfig.layout === 'full'">
          <span class="icon icon-display"></span
          ><a
            id="lnkLoginHelp"
            href="#"
            :aria-label="translate('LoginLinks.loginhelp')"
            @click="openResource(loginHelpUrl, translate('LoginLinks.loginhelp'), 'lnkLoginHelp')"
          >
            {{ translate("LoginLinks.loginhelp") }}</a
          >
        </li>

        <li>
          <span class="icon icon-display"></span
          ><a
            id="lnksupportedBrowser"
            href="#"
            :aria-label="translate('LoginLinks.supportedBrowsers')"
            @click="
              openResource(
                supportedBrowsersUrl,
                translate('LoginLinks.supportedBrowsers'),
                'lnksupportedBrowser'
              )
            "
          >
            {{ translate("LoginLinks.supportedBrowsers") }}</a
          >
        </li>
      </ul>
    </div>
    <iframeModal
      :title="iframeTitle"
      :isOpen="iframeDialogModal"
      :url="`${iframeSource}?${iFrameQueryParams}`"
      @close="close"
      :resourceclass="resourceclass"
    />
  </div>
</template>

<style scoped>
div {
  text-align: left;
}

#login_help {
  background: #e7f2fa;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-flow: row nowrap;
  padding: 1em;
}
.texas #login_help {
  background: #18649e;
  border: none;
  border-radius: 0 0 4px 4px;
  padding: 1.25em;
}
#login_help h2 {
  color: #666;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
}
.texas #login_help h2 {
  color: #fff;
}
#login_help ul {
  font-size: 0.875em;
  margin: 0 0 0 1em;
  padding-left: 0;
}
.texas #login_help ul {
  list-style: none;
  margin: 0;
}
#login_help ul li {
  margin: 0.75em 0 0;
}
.texas #login_help ul li {
  margin: 0 0 0.75em;
}
#login_help a {
  text-decoration: underline;
}
#login_help a:hover {
  color: #333;
  cursor: pointer;
}
#login_more_info {
  flex: 1 0 60%;
  padding: 1em;
}
.texas #login_more_info {
  padding: 0;
}
#login_more_support {
  border-left: 1px solid #8faacb;
  flex: 1 0 40%;
  padding: 1rem 1rem 1rem 1.5rem;
}
.texas #login_more_support {
  border: none;
  padding: 0;
}
#login_more_support ul {
  list-style: none;
  margin: 0;
}
#login_more_support ul li {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
}
#login_more_support .icon {
  color: #666;
  font-size: 2em;
  margin-right: 0.5em;
}
.texas #login_more_support .icon {
  display: none;
}
.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

@media (max-width: 420px) {
  /* .texas #login_help {
    flex-flow: column nowrap;
  } */
  .texas #login_more_info {
    flex: 1 0 55%;
  }
}
@media screen and (max-width: 610px) {
  .texas #login_help {
    flex-flow: column nowrap;
  }
  .texas #login_more_support {
    margin-top: 0.5rem;
  }
}
</style>
