import axios from "axios";
import {
  AUTH_REQUEST,
  AUTO_AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  SET_SCHOOL_YEAR,
  SET_STUDENT_INFO,
  GET_ACCESS_CODE,
} from "../actions/auth";
import { GET_SCORES_FOR_YEAR, CLEAR_STUDENT_DATA } from "../actions/studentdata";
import { APP_ERROR } from "../actions/common";
import { CONFIG } from "../../config";
import { i18n } from "../../i18n";

const state = {
  isAuthenticated: false,
  authStatus: "",
  hasLoadedOnce: false,
  studentinfo: {},
  selectedSchoolYear: 0,
  accessCode: "",
};

const getters = {
  isAuthenticated: (state) => state.isAuthenticated,
  authStatus: (state) => state.authStatus,
  studentinfo: (state) => state.studentinfo,
  selectedSchoolYear: (state) => state.selectedSchoolYear,
  accessCode: (state) => state.accessCode,
  hasScores: (state) =>
    state.studentinfo && state.studentinfo.schoolYears && state.studentinfo.schoolYears.length > 0,
};

const actions = {
  [AUTH_REQUEST]: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      axios({
        url: CONFIG.BACKEND_URL + `api/${i18n.global.locale}/auth`,
        data: user,
        method: "POST",
      })
        .then((resp) => {
          if (typeof resp.data.jwtToken !== "undefined") {
            sessionStorage.setItem("jwttoken", resp.data.jwtToken);
            sessionStorage.setItem("jwttokenexpiration", resp.data.jwtTokenExpiration);
            if (resp.data.schoolYears) {
              resp.data.schoolYears.sort();
            }
            sessionStorage.setItem("Student_Info", JSON.stringify(resp.data));
            commit(AUTH_SUCCESS, resp);
            dispatch(GET_SCORES_FOR_YEAR, { year: state.selectedSchoolYear });
          }
          resolve(resp);
        })
        .catch((err) => {
          dispatch(APP_ERROR, err);
          reject(err);
        });
    });
  },
  [AUTO_AUTH_REQUEST]: ({ commit, dispatch }, jwtToken) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      axios({
        url: CONFIG.BACKEND_URL + "api/auth/studentinfo",
        data: "jwtToken=" + jwtToken,
        method: "POST",
        headers: { Authorization: `Bearer ${jwtToken}` },
      })
        .then((resp) => {
          if (typeof resp.data.jwtToken !== "undefined") {
            sessionStorage.setItem("jwttoken", resp.data.jwtToken);
            sessionStorage.setItem("jwttokenexpiration", resp.data.jwtTokenExpiration);
            if (resp.data.schoolYears) {
              resp.data.schoolYears.sort();
            }
            sessionStorage.setItem("Student_Info", JSON.stringify(resp.data));
            commit(AUTH_SUCCESS, resp);
            dispatch(GET_SCORES_FOR_YEAR, { year: state.selectedSchoolYear });
          }
          resolve(resp);
        })
        .catch((err) => {
          dispatch(APP_ERROR, err);
          reject(err);
        });
    });
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    return new Promise((resolve) => {
      commit(AUTH_LOGOUT);
      commit(CLEAR_STUDENT_DATA);
      resolve();
    });
  },
  [SET_SCHOOL_YEAR]: ({ commit, dispatch }, { year, waitForGetScores = false }) => {
    return new Promise((resolve) => {
      commit(SET_SCHOOL_YEAR, year);
      if (waitForGetScores) {
        commit(SET_SCHOOL_YEAR, year);
        dispatch(GET_SCORES_FOR_YEAR, { year: state.selectedSchoolYear }).then(() => {
          resolve();
        });
      } else {
        dispatch(GET_SCORES_FOR_YEAR, { year: state.selectedSchoolYear });
        resolve();
      }
    });
  },

  [SET_STUDENT_INFO]: ({ commit, dispatch }) => {
    return new Promise((resolve) => {
      let sinfo = JSON.parse(sessionStorage.getItem("Student_Info"));
      commit(SET_STUDENT_INFO, sinfo);
      dispatch(GET_SCORES_FOR_YEAR, { year: state.selectedSchoolYear });
      resolve();
    });
  },
  [GET_ACCESS_CODE]: ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
      axios({
        url: CONFIG.BACKEND_URL + `api/auth/studentaccesscode`,
        data: data,
        method: "POST",
      })
        .then((resp) => {
          if (resp.data && resp.data.accesscode) {
            sessionStorage.setItem("accesscode", resp.data.accesscode);
          }
          commit(GET_ACCESS_CODE, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          dispatch(APP_ERROR, err);
          reject(err);
        });
    });
  },
};

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.authStatus = "loading";
  },
  [AUTH_SUCCESS]: (state, resp) => {
    let data = resp.data;
    if (typeof data !== "object" && typeof data !== "undefined") data = JSON.parse(data);
    state.authStatus = "success";
    state.isAuthenticated = true;
    state.hasLoadedOnce = true;
    state.studentinfo = data;
    let firstName = state.studentinfo.firstName.toString().toLowerCase();
    state.studentinfo.firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
    if (state.studentinfo.schoolYears.length > 0) {
      state.selectedSchoolYear =
        state.studentinfo.schoolYears[state.studentinfo.schoolYears.length - 1];
    }
  },
  [AUTH_LOGOUT]: (state) => {
    state.isAuthenticated = false;
    state.authStatus = "";
    state.studentinfo = {};
    state.selectedSchoolYear = 0;
    sessionStorage.clear();
  },
  [SET_SCHOOL_YEAR]: (state, year) => {
    state.selectedSchoolYear = year;
  },
  [GET_ACCESS_CODE]: (state, res) => {
    state.accessCode = res.accesscode;
  },
  [SET_STUDENT_INFO]: (state, sinfo) => {
    state.studentinfo = sinfo;
    if (state.studentinfo.schoolYears.length > 0) {
      state.selectedSchoolYear =
        state.studentinfo.schoolYears[state.studentinfo.schoolYears.length - 1];
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
